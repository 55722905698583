import { ColorPicker, message } from "antd";
import UploadLogo from "../components/Settings/UploadLogo";
import QRCodeDownloader from "../components/Settings/QRCodeDownloader";
import ViewDetails from "../components/CommonComponents/ViewDetails";

export const personalBookingData = (isCreated, onClickVisit) => [
  {
    tag: "Brand Color",
    type: "code",
    colorCode: "#D9F2DD",
    code: "#D9F2DD",
    buttonType: "Save",
  },
  {
    tag: "Company Logo",
    type: "upload",
    colorCode: "#D7EEF3",
    code: "#D7EEF3",
    buttonType: "Save",
  },
  {
    tag: "Go to external booking page preview",
    buttonType: "Visit",
    onClickVisit,
  },
  {
    tag: "Download QR Code for booking page for visitors purposes",
    buttonType: "Download",
  },
];

export const personalBookingColumn = (
  setColor,
  image,
  handleImageUpload,
  color,
  setType,
  onClickVisit,
  mutatePending,
  mutate,
  qrRef,
  data,
  isCreated,
  btnType,
  setBtnType
) => [
  {
    key: "tag",
    render: ({ tag }) => {
      return <p className="text-[16px] font-roobert-rg">{tag}</p>;
    },
  },
  {
    key: "color",
    align: "center",
    render: ({ type }) => {
      if (type === "code") {
        return (
          <ColorPicker
            defaultValue={"#D9F2DD"}
            onChange={(color) => {
              setColor(color.metaColor.toHexString());
            }}
            value={color}
          />
        );
      }
      if (type === "upload" && image) {
        return (
          <div className="flex justify-center">
            <img
              src={image?.url}
              alt="uploaded"
              className="h-[49px] object-contain w-[49px] rounded-[10px]"
            />
          </div>
        );
      }
    },
  },
  {
    key: "colorCode",
    render: ({ type }) => {
      if (type === "upload") {
        return (
          <UploadLogo image={image} handleImageUpload={handleImageUpload} />
        );
      }
      if (type === "code") {
        return <p className="">{color}</p>;
      }
    },
  },
  {
    key: "save",
    render: ({ buttonType, type }) => {
      if (buttonType === "Download") {
        return (
          <QRCodeDownloader
            qrRef={qrRef}
            disable={mutatePending}
            handleClick={() => {
              setType("download");
              setBtnType("download");
              onClickVisit("download");
            }}
          />
        );
      }
      if (buttonType === "Visit") {
        return (
          <ViewDetails
            handleClick={() => {
              setBtnType("visit");
              onClickVisit("visit");
            }}
            text={buttonType}
            disable={mutatePending}
            isLoading={btnType === "visit" && mutatePending}
          />
        );
      }
      if (buttonType && type === "upload") {
        return (
          <ViewDetails
            handleClick={() => {
              if (!image)
                return message.error("Please first upload company logo");
              if (!image?.isFirebase || data?.brand_color !== color) {
                setBtnType("upload");
                mutate({
                  image,
                  color,
                });
              }
            }}
            isLoading={mutatePending && btnType === "upload"}
            disable={mutatePending}
            text={buttonType}
          />
        );
      }
      if (buttonType && type === "code") {
        return (
          <ViewDetails
            handleClick={() => {
              if (!image)
                return message.error("Please first upload company logo");
              if (!image?.isFirebase || data?.brand_color !== color) {
                setBtnType("code");
                mutate({
                  image,
                  color,
                });
              }
            }}
            isLoading={mutatePending && btnType === "code"}
            disable={mutatePending}
            text={buttonType}
          />
        );
      }
    },
  },
];

import { useEffect } from "react";
import AntdTable from "../CommonComponents/Table/Table";
import {
  personalBookingColumn,
  personalBookingData,
} from "../../constants/personalBooking";
import useExternalBooking from "../../hooks/getData/useExternalBooking";

const PersonalBooking = () => {
  const {
    data,
    isLoading,
    image,
    setImage,
    color,
    setColor,
    setType,
    qrRef,
    handleImageUpload,
    mutate,
    mutatePending,
    onClickVisit,
    btnType,
    setBtnType,
  } = useExternalBooking();

  useEffect(() => {
    if (data) {
      setImage({
        url: data?.company_logo,
        isFirebase: true,
      });
      setColor(data?.brand_color);
    }
  }, [data, setColor, setImage]);

  return (
    <div
      id="personal-booking"
      className="bg-[#FFFFFF] rounded-[20px] mt-[35px] py-[24px] px-[40px] 2xl:mt-[53px]"
    >
      <h1 className="text-[28px] font-roobert-rg 2xl:text-[35px] text-dark-blue leading-[50px]">
        Personal booking page for assets
      </h1>
      <AntdTable
        columns={personalBookingColumn(
          setColor,
          image,
          handleImageUpload,
          color,
          setType,
          onClickVisit,
          mutatePending,
          mutate,
          qrRef,
          data,
          data !== null,
          btnType,
          setBtnType
        )}
        data={personalBookingData(data === null)}
        loading={isLoading}
      />
    </div>
  );
};

export default PersonalBooking;

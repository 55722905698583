import { Button, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Eye from "../../assets/images/Eye.png";
const ViewDetails = ({
  handleClick,
  text,
  className,
  type,
  isLoading,
  disable,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={handleClick}
      type={type}
      loading={isLoading}
      disable={disable}
      className={`${className} h-[30px] border my-1  transparent-btn border-[#A5C5CC] bg-transparent text-center   rounded-[30px] `}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <div className="flex items-center text-[14px] pr-2 h-full font-roobert_l justify-around gap-x-[10px]">
          <img src={Eye} alt="" />
          <div className="">{text ? text : t("Seedetails")}</div>
        </div>
      )}
    </Button>
  );
};

export default ViewDetails;

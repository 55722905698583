import React, { useState } from "react";
import Layout from "../Layout/Layout";
import PageHeaders from "../components/CommonComponents/PageHeaders";
import InvoiceInformation from "../components/Settings/InvoiceInformation";
import AccountSetting from "../components/Settings/AccountSetting";
import QueueManagement from "../components/Settings/QueueManagement";
import CustomSettingCard from "../components/Settings/CustomSettingCard";
// import SubscriptionCard from "../components/Settings/SubscriptionCard";
// import PreviousInvoices from "../components/CommonComponents/PreviousInvoices";
import useGetDataForSettings from "../hooks/getData/useGetDataForSettings";
import UserAccounts from "../components/Settings/UserAccounts";
import YourBank from "../components/Settings/YourBank";
import AddUserModal from "../components/Settings/AddUserModal";
import GetStartedPayout from "../components/Settings/GetStartedPayout";
import useAccountStatus from "../hooks/getData/useAccountStatus";
import { useQuery } from "react-query";
import { getPayouts } from "../API/marina";
import LinkingFailed from "../components/Settings/LinkingFailed";
import { disabled, enabled, failed, pending } from "../constants/constants";
import Dialog from "../components/CommonComponents/Modal/Dialog";
import PersonalBooking from "../components/Settings/PersonalBooking";
import { useParams } from "react-router-dom";
import { decryptNumber } from "../utils/encrypt";
const Settings = () => {
  const { id } = useParams();
  console.log(typeof id);

  console.log(decryptNumber(id));

  const {
    columns,
    userAccountsColumns,
    editAccountDetails,
    filterUsers,
    usersError,
    usersIsFetching,
    usersIsLoading,
    handleAddUserModal,
    openAddUserModal,
    onFinishAddOrEditUser,
    onSelectChange,
    selectedRowKeys,
    t,
    addUserLoading,
    addUserFetching,
    handleDelete,
    deleteUserFetching,
    deleteUserLoading,
    userId,
    params,
  } = useGetDataForSettings();
  const [type, setType] = useState(null);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);

  const {
    data,
    mutate: createAccount,
    isLoading,
    isFetching,
    mutateLoading,
  } = useAccountStatus({
    enabled: true,
    setIsLoading: setLoading,
    type: "setting",
  });

  const {
    data: payouts,
    isLoading: payoutsLoading,
    isFetching: payoutsFetching,
    error,
  } = useQuery({
    queryKey: ["get-payouts"],
    queryFn: async () => {
      return await getPayouts(userId);
    },
  });
  const { status, data: accountData } = data ?? {};
  const handleCreateAccount = () => {
    createAccount({
      userId,
      flow: false,
    });
  };
  const { last_digit4, bank_name } = data?.data[0] ?? {};
  const { stripe_account_id } = data ?? {};
  const linking = params.get("linking");

  return (
    <Layout page={t("settings")}>
      <Dialog
        isModalOpen={openAddUserModal}
        onCancelModal={() => handleAddUserModal(false)}
        component={
          <AddUserModal
            handleClose={handleAddUserModal}
            onFinish={onFinishAddOrEditUser}
            addUserLoading={addUserLoading}
            addUserFetching={addUserFetching}
            type={type}
            handleDelete={() => handleDelete()}
            deleteUserFetching={deleteUserFetching}
            deleteUserLoading={deleteUserLoading}
          />
        }
      />
      <div className="px-[25px] font-roobert-rg pb-[80px]">
        <PageHeaders heading={t("settings")} />
        <InvoiceInformation t={t} />
        <div
          className={`grid ${
            role === "marina" ? "xl:grid-cols-2 grids-col-1" : ""
          } h-auto 2xl:mt-[53px] mt-[35px] gap-[24px]`}
        >
          <div className="">
            <AccountSetting t={t} />
          </div>
          {role === "marina" && (
            <>
              <div className="">
                <QueueManagement t={t} />
              </div>
              <div className="">
                <CustomSettingCard
                  heading={t("memberShip")}
                  subHeading={t("feeForCM")}
                  priceTerm={"NOK/" + t("annually")}
                  t={t}
                />
              </div>
              <div className="">
                <CustomSettingCard
                  heading={t("registrationFee")}
                  subHeading={t("registrationFeeForCM")}
                  priceTerm="NOK"
                  t={t}
                />
              </div>
              <div className="">
                <CustomSettingCard
                  heading={t("pinCodeMembers")}
                  subHeading={t("pinCodeMembers1")}
                  priceTerm={t("mustBe4Digits")}
                  name={"pin_code"}
                  t={t}
                  rules={[
                    {
                      required: true,
                      message: t("enterPinCode") + "!",
                    },
                  ]}
                  editAccountDetails={editAccountDetails}
                />
              </div>
            </>
          )}
        </div>
        {/* <div className="2xl:mt-[50px] pt-[20px] pb-[30px] bg-[#FFFFFF] rounded-[20px] xl:pl-[40px] px-[20px]  xl:pr-[96px] mt-[35px]">
          <PageHeaders
            heading={t("subscription") + " " + t("and") + " " + t("license")}
          />
          <div className="xl::w-[55%] w-full mt-[30px]">
            <SubscriptionCard t={t} subscription={true} />
          </div>
          <div className="">
            <PreviousInvoices invoiceColumns={columns} />
          </div>
        </div> */}
        <UserAccounts
          users={filterUsers}
          isLoading={usersIsLoading}
          isFetching={usersIsFetching}
          usersError={usersError}
          userAccountsColumns={userAccountsColumns}
          handleAddUserModal={handleAddUserModal}
          openAddUserModal={openAddUserModal}
          onSelectChange={onSelectChange}
          selectedRowKeys={selectedRowKeys}
          setType={setType}
          t={t}
        />

        {linking !== failed && (
          <>
            {status === disabled ? (
              <GetStartedPayout
                t={t}
                handleCreateAccount={handleCreateAccount}
                mutateLoading={mutateLoading}
              />
            ) : (status === pending || status === null || status === enabled) &&
              accountData?.length === 0 ? (
              <GetStartedPayout
                t={t}
                handleCreateAccount={handleCreateAccount}
                mutateLoading={mutateLoading}
              />
            ) : (
              <YourBank
                t={t}
                lastDigits={last_digit4}
                payouts={payouts}
                isLoading={payoutsLoading}
                isFetching={payoutsFetching}
                error={error}
                bankName={bank_name}
                userId={userId}
                bankKey={stripe_account_id}
              />
            )}
          </>
        )}
        {linking === failed && (
          <LinkingFailed
            t={t}
            handleCreateAccount={handleCreateAccount}
            mutateLoading={mutateLoading}
          />
        )}
        <PersonalBooking />
      </div>
    </Layout>
  );
};

export default Settings;

import React from "react";
import { QRCode } from "antd";
import ViewDetails from "../CommonComponents/ViewDetails";

const QRCodeDownloader = ({ disable, qrRef, handleClick }) => {
  const userId = localStorage.getItem("userId");
  return (
    <div className="flex flex-col ">
      <div ref={qrRef} className="hidden">
        <QRCode
          value={`http://localhost:5174/en/external-booking/all/${userId}`}
          size={200}
        />
      </div>
      <ViewDetails
        handleClick={handleClick}
        text="Download"
        className={"w-[140px]"}
        disable={disable}
      />
    </div>
  );
};

export default QRCodeDownloader;

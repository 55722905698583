import React from "react";
import Label from "../CommonComponents/Label";
import SelectInput from "../CommonComponents/SelectInput";
import FormInput from "../CommonComponents/FormInput";
import PhoneNumberInput from "../CommonComponents/PhoneNumberInput";
import { Form } from "antd";
import ButtonDark from "../CommonComponents/ButtonDark";
import useGetDataForSignup from "../../hooks/getData/useGetDataForSignup";
import { useUserDataAndNavigation } from "../../hooks/useUserDataAndNavigation";

const SecondStep = () => {
  const {
    initialValues,
    countryOptions,
    options,
    changeBilling,
    changeCountry,
    finishStepTwo,
    billing,
    activeNum,
    setActiveNum,
    country_name,
  } = useGetDataForSignup();
  const { t, lang } = useUserDataAndNavigation();
  const country =
    lang === "de" ? "Germany" : lang === "se" ? "Sweden" : "Norway";
  const defaultCountryValue = country_name ? country_name : country;
  return (
    <Form
      name="basic"
      onFinish={finishStepTwo}
      autoComplete="off"
      initialValues={initialValues}
    >
      <div className="grid grid-cols-2 gap-x-3 mt-[20px]  ">
        <div className="">
          <Label text={t("Contactperson") + "*"} />
          <FormInput
            placeHolder={t("Contactperson")}
            name={"full_name"}
            type={"text"}
            rules={[
              {
                required: true,
                message: t("enterContactPerson"),
              },
            ]}
          />
        </div>
        <div className="h-full">
          <Label text={t("country") + "*"} />
          <SelectInput
            options={countryOptions}
            defaultValue={defaultCountryValue}
            handleChange={changeCountry}
            key={lang || country_name}
          />
        </div>
        <div className="">
          <Label text={t("Phonenumber") + "*"} />
          <PhoneNumberInput
            activeNum={activeNum}
            setActiveNum={setActiveNum}
            t={t}
            lang={lang}
            name="company_phone"
            key={activeNum}
          />
        </div>
        <div className="">
          <Label text={t("email") + "*"} />
          <FormInput
            placeHolder={t("email")}
            name={"email"}
            type={"email"}
            rules={[
              {
                required: true,
                message: t("enterEmail"),
              },
            ]}
          />
        </div>
        <div className="">
          <Label text={t("Billingemail") + "*"} />
          <FormInput
            placeHolder="hei@gmail.com"
            name={"billing_email"}
            type={"email"}
            rules={[
              {
                required: true,
                message: t("enterBillingEmail"),
              },
            ]}
          />
        </div>
        <div className="select-country">
          <Label text={t("bill/ehf") + "*"} />
          <SelectInput
            options={options}
            defaultValue={billing}
            handleChange={changeBilling}
          />
        </div>
      </div>
      <div className="text-white mt-[20px] 2xl:mt-[30px] flex justify-center">
        <div className="text-center">
          <ButtonDark
            styleClasses={
              "bg-dark-blue text-[#FFFFFF] h-[50px] 2xl:h-[60px] w-[150px] px-[50px] py-[10px] !rounded-[50px]"
            }
            text={t("next")}
            type={"submit"}
          />
        </div>
      </div>
    </Form>
  );
};

export default SecondStep;

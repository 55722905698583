import CryptoJS from "crypto-js";
export const encryptNumber = (number) => {
  const ciphertext = CryptoJS.AES.encrypt(
    number.toString(),
    "@shipohoiencryptionkey!##$"
  ).toString();
  return encodeURIComponent(ciphertext);
};

export const decryptNumber = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(ciphertext),
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  return parseInt(bytes.toString(CryptoJS.enc.Utf8), 10);
};

import React, { useState } from "react";
import { Form, Select, Input } from "antd";
import NorwayIcon from "../../assets/icons/NorwayIcon";
import ArrowDownDark from "../../assets/icons/ArrowDownDark";
import { t } from "i18next";
import Sweden from "../../assets/images/Sweden.png";
import Germany from "../../assets/images/Germany.png";

const { Option } = Select;
const prefixSelector = (handleChange, activeNum, lang) => (
  <Select
    defaultValue={
      activeNum
        ? activeNum
        : lang === "se"
        ? `+46`
        : lang === "de"
        ? `+49`
        : `+47`
    }
    key={activeNum || lang}
    suffixIcon={<ArrowDownDark />}
    onChange={handleChange}
    className="h-[41px] rounded-l-[30px] bg-[#FFFFFF] !border-none !outline-none"
  >
    <Option value="+47" className="text-[16px]  text-[#A3A3A3]">
      <NorwayIcon />
    </Option>
    <Option value="+46">
      <img src={Sweden} className="h-[20px] rounded-[5px] w-[28px]" alt="" />
    </Option>
    <Option value="+49">
      <img src={Germany} className="h-[30px] !rounded-[10px] w-[28px]" alt="" />
    </Option>
  </Select>
);
const PhoneNumberInput = ({
  t,
  activeNum,
  setActiveNum,
  lang,
  name = "phone",
}) => {
  const handleChange = (val) => {
    console.log(val);
    setActiveNum(val);
  };
  const placeHolder =
    activeNum === "+47"
      ? `+47 ${t("Phonenumber")}`
      : activeNum === "+46"
      ? `+46 ${t("Phonenumber")}`
      : activeNum === "+49"
      ? `+49 ${t("Phonenumber")}`
      : lang === "se"
      ? `+46 ${t("Phonenumber")}`
      : lang === "de"
      ? `+49 ${t("Phonenumber")}`
      : `+47 ${t("Phonenumber")}`;
  return (
    <div className="phone-number">
      <Form.Item
        name={name}
        type="number"
        className=""
        rules={[
          {
            required: true,
            message: t("enterPhoneNumber"),
          },
        ]}
      >
        <Input
          className="!h-[40px] !border-none !outline-none"
          addonBefore={prefixSelector(handleChange, activeNum, lang)}
          type="number"
          key={activeNum}
          placeholder={placeHolder}
        />
      </Form.Item>
    </div>
  );
};

export default PhoneNumberInput;

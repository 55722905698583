import React from "react";
import Eye from "../../assets/images/Eye.png";

const UploadLogo = ({ image, handleImageUpload }) => {
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        className="hidden"
        id="image-upload"
        onChange={handleImageUpload}
      />
      <label htmlFor="image-upload" className="cursor-pointer  ">
        <div className="h-[30px] border my-1  transparent-btn border-[#A5C5CC] !w-[120px] bg-transparent text-center   rounded-[30px] ">
          <div className="flex items-center text-[14px] pr-2 h-full font-roobert_l justify-around gap-x-[10px]">
            <img src={Eye} alt="" />
            <div className="">Upload</div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default UploadLogo;

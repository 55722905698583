import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getExternalBookingSettings,
  updateExternalCompanyInfo,
} from "../../API/marina";
import { onClickQRDownload } from "../../utils/QRdownload";
import { message } from "antd";
import { encryptNumber } from "../../utils/encrypt";
import { useNavigate } from "react-router-dom";

const useExternalBooking = () => {
  const [color, setColor] = useState("#D9F2DD");
  const [image, setImage] = useState();
  const [type, setType] = useState("");
  const qrRef = useRef(null);
  const [btnType, setBtnType] = useState();
  let userId = localStorage.getItem("userId");
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["get-external-booking-settings"],
    queryFn: () => getExternalBookingSettings(userId),
  });
  const navigate = useNavigate();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage({
        isFirebase: false,
        url: imageUrl,
      });
    }
  };

  const { mutate, isLoading: mutatePending } = useMutation({
    mutationKey: ["update-booking-company"],
    mutationFn: (Data) => {
      return updateExternalCompanyInfo(userId, Data);
    },
    onSuccess: (Data) => {
      refetch();
      if (Data.type === "download") {
        onClickQRDownload(qrRef);
      } else if (Data.type === "visit") {
        console.log(encryptNumber(userId));

        // window.open(
        //   `http://localhost:5174/en/external-booking/all/${encryptNumber(
        //     userId
        //   )}`,
        //   '_blank'
        // );
      } else {
        const { res } = Data;
        message.success(res.message);
      }
    },
  });

  const onClickVisit = (type) => {
    if (!image) return message.error("Please first upload company logo");
    if (data === null) {
      mutate({
        image,
        color,
        type,
      });
    } else {
      if (type !== "download") {
        setType("visit");
        navigate();
        window.open(
          `http://localhost:5173/en/external-booking/all/${encryptNumber(
            userId
          )}`,
          "_blank"
        );
      } else {
        setType("download");
        onClickQRDownload(qrRef);
      }
    }
  };

  return {
    data,
    isLoading,
    image,
    setImage,
    color,
    setColor,
    type,
    setType,
    qrRef,
    handleImageUpload,
    mutate,
    mutatePending,
    onClickVisit,
    btnType,
    setBtnType,
  };
};

export default useExternalBooking;

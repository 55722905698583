export const generateAndDownloadQR = (qrRef) => {
  const canvas = qrRef.current.querySelector("canvas");
  if (canvas) {
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "qr-code.png";
    link.click();
  }
};

export const onClickQRDownload = (qrRef) => {
  const qrContainer = qrRef.current;
  if (qrContainer) {
    qrContainer.classList.remove("hidden");
    setTimeout(() => {
      generateAndDownloadQR(qrRef);
      qrContainer.classList.add("hidden");
    }, 100);
  }
};
